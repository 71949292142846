var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-row",
    {
      staticClass: "row",
      style: { lineHeight: _vm.lineHeight1 },
      attrs: { align: "middle" },
    },
    [
      _c(
        "a-form-model-item",
        { attrs: { prop: _vm.prop } },
        [_vm._t("default")],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }