<template>
  <a-row class="row" align="middle">
    <a-form-model-item>
      <label class="label">{{ label }}</label>
    </a-form-model-item>
  </a-row>
</template>

<script>
export default {
  props: {
    label: String,
  },
}
</script>

<style lang="less" scoped>
.row {
  line-height: 40px;
  text-align: right;
}
.label {
  margin-right: 10px;
}
</style>