var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "detail-layout",
    { attrs: { title: _vm.title } },
    [
      _vm.isAddPlatform
        ? _c("div", { attrs: { slot: "actions" }, slot: "actions" }, [
            _c(
              "div",
              { staticClass: "actions flex jc-end mb-4" },
              [
                _c(
                  "a-button",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.tab == "1",
                        expression: "tab == '1'",
                      },
                    ],
                    staticClass: "mr-3",
                    attrs: { icon: "save", loading: _vm.nextProcessing },
                    on: { click: _vm.savePlatform },
                  },
                  [_vm._v("保存")]
                ),
                _c(
                  "a-button",
                  {
                    attrs: { type: "primary", loading: _vm.nextProcessing },
                    on: { click: _vm.primaryButtonClick },
                  },
                  [_vm._v(_vm._s(_vm.primaryButtonText))]
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
      _c(
        "a-spin",
        { attrs: { spinning: _vm.nextProcessing } },
        [
          _c(
            "a-tabs",
            { attrs: { activeKey: _vm.tab }, on: { change: _vm.tabChange } },
            [
              _c("a-tab-pane", { key: "1", attrs: { tab: "平台信息" } }, [
                _vm.isAddPlatform
                  ? _c(
                      "div",
                      [
                        _c(
                          "a-form-model",
                          _vm._b(
                            {
                              ref: "form",
                              attrs: { model: _vm.detail, rules: _vm.rules },
                            },
                            "a-form-model",
                            _vm.layout,
                            false
                          ),
                          [
                            _c(
                              "a-form-model-item",
                              {
                                attrs: {
                                  label: "平台名称",
                                  prop: "platformName",
                                },
                              },
                              [
                                _c("a-input", {
                                  attrs: { placeholder: "请输入平台名称" },
                                  model: {
                                    value: _vm.detail.platformName,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.detail, "platformName", $$v)
                                    },
                                    expression: "detail.platformName",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "a-form-model-item",
                              { attrs: { label: "序号" } },
                              [
                                _c("a-input", {
                                  attrs: { placeholder: "序号" },
                                  model: {
                                    value: _vm.detail.sequenceNo,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.detail, "sequenceNo", $$v)
                                    },
                                    expression: "detail.sequenceNo",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "a-form-model-item",
                              {
                                attrs: {
                                  label: "所属部门",
                                  prop: "departmentId",
                                },
                              },
                              [
                                _c("a-tree-select", {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    treeDefaultExpandAll: true,
                                    "tree-data": _vm.departList,
                                    "search-placeholder": "选择部门",
                                  },
                                  on: { change: _vm.changeDepart },
                                  model: {
                                    value: _vm.detail.departmentId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.detail, "departmentId", $$v)
                                    },
                                    expression: "detail.departmentId",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "a-form-model-item",
                              {
                                attrs: { label: "统筹", prop: "adminUserIds" },
                              },
                              [
                                _c(
                                  "a-select",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      mode: "multiple",
                                      allowClear: "",
                                      placeholder: "请选择统筹人员",
                                      "option-filter-prop": "label",
                                    },
                                    model: {
                                      value: _vm.detail.adminUserIds,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.detail,
                                          "adminUserIds",
                                          $$v
                                        )
                                      },
                                      expression: "detail.adminUserIds",
                                    },
                                  },
                                  _vm._l(_vm.userList, function (user) {
                                    return _c(
                                      "a-select-option",
                                      {
                                        key: user.userId,
                                        attrs: {
                                          disabled:
                                            _vm.detail.adminUserIds.length,
                                          label: user.userName,
                                        },
                                      },
                                      [
                                        _c("img", {
                                          staticStyle: {
                                            width: "30px",
                                            height: "30px",
                                            "border-radius": "50%",
                                            "margin-right": "10px",
                                          },
                                          attrs: { src: user.avatar, alt: "" },
                                        }),
                                        _c("span", [
                                          _vm._v(_vm._s(user.userName)),
                                        ]),
                                      ]
                                    )
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "a-form-model-item",
                              { attrs: { label: "本地化", prop: "canTra" } },
                              [
                                _c("a-radio-group", {
                                  attrs: {
                                    options: [
                                      { label: "是", value: true },
                                      { label: "否", value: false },
                                    ],
                                  },
                                  model: {
                                    value: _vm.detail.isLocal,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.detail, "isLocal", $$v)
                                    },
                                    expression: "detail.isLocal",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "a-form-model-item",
                              { attrs: { label: "使用系统" } },
                              [
                                _c("a-checkbox-group", {
                                  staticStyle: {
                                    display: "flex",
                                    "line-height": "40px",
                                  },
                                  attrs: { options: _vm.systemList },
                                  model: {
                                    value: _vm.detail.systemTypes,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.detail, "systemTypes", $$v)
                                    },
                                    expression: "detail.systemTypes",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm.detail.systemTypes &&
                            _vm.detail.systemTypes.some(
                              (item) =>
                                item == "IMAGE_TRANSLATION_SYSTEM" ||
                                item == "TEXT_TRANSLATION_SYSTEM"
                            )
                              ? _c(
                                  "a-form-model-item",
                                  { attrs: { label: "翻译语种" } },
                                  [
                                    _c(
                                      "a-select",
                                      {
                                        staticStyle: {
                                          width: "150px",
                                          "margin-right": "5px",
                                        },
                                        attrs: {
                                          showSearch: "",
                                          allowClear: "",
                                          showArrow: "",
                                          placeholder: "请选择源语种",
                                          "option-filter-prop": "label",
                                        },
                                        model: {
                                          value: _vm.detail.sourceLang,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.detail,
                                              "sourceLang",
                                              $$v
                                            )
                                          },
                                          expression: "detail.sourceLang",
                                        },
                                      },
                                      [
                                        _c(
                                          "a-select-option",
                                          {
                                            attrs: {
                                              value: "SIMPLIFIED_CHINESE",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                  简体中文\n                "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "a-select-option",
                                          {
                                            attrs: {
                                              value: "TRADITIONAL_CHINESE",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                  繁體中文\n                "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "a-select-option",
                                          { attrs: { value: "KOREAN" } },
                                          [
                                            _vm._v(
                                              "\n                  한국어\n                "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "a-select-option",
                                          { attrs: { value: "JAPANESE" } },
                                          [
                                            _vm._v(
                                              "\n                  日本語\n                "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "a-select-option",
                                          { attrs: { value: "ENGLISH" } },
                                          [
                                            _vm._v(
                                              "\n                  ENGLISH\n                "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "a-select-option",
                                          { attrs: { value: "FRENCH" } },
                                          [
                                            _vm._v(
                                              "\n                  Français\n                "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "a-select-option",
                                          { attrs: { value: "GERMAN" } },
                                          [
                                            _vm._v(
                                              "\n                  Deutsch\n                "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "a-select-option",
                                          { attrs: { value: "italiano" } },
                                          [
                                            _vm._v(
                                              "\n                  ITALIAN\n                "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c("a-icon", {
                                      attrs: { type: "arrow-right" },
                                    }),
                                    _c(
                                      "a-select",
                                      {
                                        staticStyle: {
                                          width: "150px",
                                          "margin-left": "5px",
                                        },
                                        attrs: {
                                          showSearch: "",
                                          allowClear: "",
                                          showArrow: "",
                                          placeholder: "请选择目标语种",
                                          "option-filter-prop": "label",
                                        },
                                        model: {
                                          value: _vm.detail.targetLang,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.detail,
                                              "targetLang",
                                              $$v
                                            )
                                          },
                                          expression: "detail.targetLang",
                                        },
                                      },
                                      [
                                        _c(
                                          "a-select-option",
                                          {
                                            attrs: {
                                              value: "SIMPLIFIED_CHINESE",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                  简体中文\n                "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "a-select-option",
                                          {
                                            attrs: {
                                              value: "TRADITIONAL_CHINESE",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                  繁體中文\n                "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "a-select-option",
                                          { attrs: { value: "KOREAN" } },
                                          [
                                            _vm._v(
                                              "\n                  한국어\n                "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "a-select-option",
                                          { attrs: { value: "JAPANESE" } },
                                          [
                                            _vm._v(
                                              "\n                  日本語\n                "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "a-select-option",
                                          { attrs: { value: "ENGLISH" } },
                                          [
                                            _vm._v(
                                              "\n                  ENGLISH\n                "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "a-select-option",
                                          { attrs: { value: "FRENCH" } },
                                          [
                                            _vm._v(
                                              "\n                  Français\n                "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "a-select-option",
                                          { attrs: { value: "GERMAN" } },
                                          [
                                            _vm._v(
                                              "\n                  Deutsch\n                "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "a-select-option",
                                          { attrs: { value: "italiano" } },
                                          [
                                            _vm._v(
                                              "\n                  ITALIAN\n                "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "a-form-model-item",
                              { attrs: { label: "是否考核", prop: "hasKpi" } },
                              [
                                _c(
                                  "a-radio-group",
                                  {
                                    model: {
                                      value: _vm.detail.hasKpi,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.detail, "hasKpi", $$v)
                                      },
                                      expression: "detail.hasKpi",
                                    },
                                  },
                                  [
                                    _c("a-radio", { attrs: { value: true } }, [
                                      _vm._v(
                                        "\n                  是\n                "
                                      ),
                                    ]),
                                    _c("a-radio", { attrs: { value: false } }, [
                                      _vm._v(
                                        "\n                  否\n                "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "a-form-model-item",
                              {
                                attrs: { label: "结算单位", prop: "calcUnits" },
                              },
                              [
                                _c(
                                  "a-select",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      "option-filter-prop": "label",
                                      mode: "multiple",
                                      placeholder: "请选择",
                                    },
                                    on: { filterOption: _vm.filterOption },
                                    model: {
                                      value: _vm.calcUnits,
                                      callback: function ($$v) {
                                        _vm.calcUnits = $$v
                                      },
                                      expression: "calcUnits",
                                    },
                                  },
                                  [
                                    _c(
                                      "a-select-option",
                                      {
                                        attrs: {
                                          label: "个",
                                          disabled:
                                            _vm.calcUnits.length > 1 &&
                                            _vm.calcUnits.every(
                                              (item) => item != "EACH"
                                            ),
                                          value: "EACH",
                                        },
                                      },
                                      [_vm._v("个")]
                                    ),
                                    _c(
                                      "a-select-option",
                                      {
                                        attrs: {
                                          label: "话",
                                          disabled:
                                            _vm.calcUnits.length > 1 &&
                                            _vm.calcUnits.every(
                                              (item) => item != "CHAPTER"
                                            ),
                                          value: "CHAPTER",
                                        },
                                      },
                                      [_vm._v("话")]
                                    ),
                                    _c(
                                      "a-select-option",
                                      {
                                        attrs: {
                                          label: "部",
                                          disabled:
                                            _vm.calcUnits.length > 1 &&
                                            _vm.calcUnits.every(
                                              (item) => item != "VOLUME"
                                            ),
                                          value: "VOLUME",
                                        },
                                      },
                                      [_vm._v("部")]
                                    ),
                                    _c(
                                      "a-select-option",
                                      {
                                        attrs: {
                                          label: "页",
                                          disabled:
                                            _vm.calcUnits.length > 1 &&
                                            _vm.calcUnits.every(
                                              (item) => item != "PAGE"
                                            ),
                                          value: "PAGE",
                                        },
                                      },
                                      [_vm._v("页")]
                                    ),
                                    _c(
                                      "a-select-option",
                                      {
                                        attrs: {
                                          label: "千字",
                                          disabled:
                                            _vm.calcUnits.length > 1 &&
                                            _vm.calcUnits.every(
                                              (item) => item != "THOUSAND"
                                            ),
                                          value: "THOUSAND",
                                        },
                                      },
                                      [_vm._v("千字")]
                                    ),
                                    _c(
                                      "a-select-option",
                                      {
                                        attrs: {
                                          label: "字",
                                          disabled:
                                            _vm.calcUnits.length > 1 &&
                                            _vm.calcUnits.every(
                                              (item) => item != "WORD"
                                            ),
                                          value: "WORD",
                                        },
                                      },
                                      [_vm._v("字")]
                                    ),
                                    _c(
                                      "a-select-option",
                                      {
                                        attrs: {
                                          label: "格",
                                          disabled:
                                            _vm.calcUnits.length > 1 &&
                                            _vm.calcUnits.every(
                                              (item) => item != "GRID"
                                            ),
                                          value: "GRID",
                                        },
                                      },
                                      [_vm._v("格")]
                                    ),
                                    _c(
                                      "a-select-option",
                                      {
                                        attrs: {
                                          label: "%",
                                          disabled:
                                            _vm.calcUnits.length > 1 &&
                                            _vm.calcUnits.every(
                                              (item) => item != "PERCENT"
                                            ),
                                          value: "PERCENT",
                                        },
                                      },
                                      [_vm._v("%")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm.detail.canTra
                              ? _c(
                                  "a-form-model-item",
                                  { attrs: { label: "章节标题" } },
                                  [
                                    _c("a-checkbox", {
                                      attrs: { placeholder: "" },
                                      model: {
                                        value: _vm.detail.hasChapterTitle,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.detail,
                                            "hasChapterTitle",
                                            $$v
                                          )
                                        },
                                        expression: "detail.hasChapterTitle",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.detail.canTra
                              ? _c(
                                  "a-form-model-item",
                                  { attrs: { label: "章节封面" } },
                                  [
                                    _c("a-checkbox", {
                                      attrs: { placeholder: "" },
                                      model: {
                                        value: _vm.detail.hasChapterCoverImage,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.detail,
                                            "hasChapterCoverImage",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "detail.hasChapterCoverImage",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.detail.canTra
                              ? _c(
                                  "a-form-model-item",
                                  { attrs: { label: "初翻" } },
                                  [
                                    _c("a-checkbox", {
                                      attrs: { placeholder: "" },
                                      model: {
                                        value: _vm.detail.isSimple,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.detail, "isSimple", $$v)
                                        },
                                        expression: "detail.isSimple",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _c("div", [
                      _c(
                        "div",
                        { staticClass: "actions flex jc-end mb-4" },
                        [
                          _c(
                            "a-button",
                            {
                              directives: [
                                {
                                  name: "has",
                                  rawName: "v-has",
                                  value: "platform:active",
                                  expression: "'platform:active'",
                                },
                              ],
                              staticClass: "mr-3",
                              attrs: {
                                loading: _vm.activeLoading,
                                type: _vm.detail.active ? "primary" : "",
                                icon: "check",
                                disabled: _vm.detail.active,
                              },
                              on: { click: _vm.saveActive },
                            },
                            [_vm._v("\n              BOSS确认\n            ")]
                          ),
                          _vm.checkPermission("platform:active:view") &&
                          !_vm.checkPermission("platform:active")
                            ? _c(
                                "a-button",
                                {
                                  staticClass: "mr-3",
                                  attrs: { icon: "check", disabled: true },
                                },
                                [
                                  _vm._v(
                                    "\n              BOSS确认\n            "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm.detail.isTongChou
                            ? _c(
                                "a-button",
                                {
                                  directives: [
                                    {
                                      name: "has",
                                      rawName: "v-has",
                                      value: "platform:list:button:delete",
                                      expression:
                                        "'platform:list:button:delete'",
                                    },
                                  ],
                                  staticClass: "mr-3",
                                  attrs: { type: "danger", icon: "delete" },
                                  on: { click: _vm.delPlatform },
                                },
                                [_vm._v("删除")]
                              )
                            : _vm._e(),
                          _vm.isEditPlatform
                            ? _c(
                                "a-button",
                                {
                                  staticClass: "mr-3",
                                  attrs: { type: "default" },
                                  on: { click: _vm.editCancel },
                                },
                                [_vm._v("取消")]
                              )
                            : _vm._e(),
                          _vm.detail.isTongChou
                            ? _c(
                                "a-button",
                                {
                                  directives: [
                                    {
                                      name: "has",
                                      rawName: "v-has",
                                      value: "platform:info:button:edit",
                                      expression: "'platform:info:button:edit'",
                                    },
                                  ],
                                  attrs: { type: "primary", icon: "edit" },
                                  on: { click: _vm.editPlatform },
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        !_vm.isEditPlatform ? "编辑" : "保存"
                                      ) +
                                      "\n            "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm.isEditPlatform
                        ? _c(
                            "div",
                            [
                              _c(
                                "a-form-model",
                                _vm._b(
                                  {
                                    ref: "form",
                                    attrs: {
                                      model: _vm.detail,
                                      rules: _vm.rules,
                                    },
                                  },
                                  "a-form-model",
                                  _vm.layout,
                                  false
                                ),
                                [
                                  _c(
                                    "a-form-model-item",
                                    {
                                      attrs: {
                                        label: "平台名称",
                                        prop: "platformName",
                                      },
                                    },
                                    [
                                      _c("a-input", {
                                        attrs: {
                                          placeholder: "请输入平台名称",
                                        },
                                        model: {
                                          value: _vm.detail.platformName,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.detail,
                                              "platformName",
                                              $$v
                                            )
                                          },
                                          expression: "detail.platformName",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "a-form-model-item",
                                    { attrs: { label: "序号" } },
                                    [
                                      _c("a-input", {
                                        attrs: { placeholder: "序号" },
                                        model: {
                                          value: _vm.detail.sequenceNo,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.detail,
                                              "sequenceNo",
                                              $$v
                                            )
                                          },
                                          expression: "detail.sequenceNo",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "a-form-model-item",
                                    {
                                      attrs: {
                                        label: "所属部门",
                                        prop: "departmentId",
                                      },
                                    },
                                    [
                                      _c("a-tree-select", {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          treeDefaultExpandAll: true,
                                          "tree-data": _vm.departList,
                                          "search-placeholder": "选择部门",
                                        },
                                        on: { change: _vm.changeDepart },
                                        model: {
                                          value: _vm.detail.departmentId,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.detail,
                                              "departmentId",
                                              $$v
                                            )
                                          },
                                          expression: "detail.departmentId",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "a-form-model-item",
                                    {
                                      attrs: {
                                        label: "统筹",
                                        prop: "adminUserIds",
                                      },
                                    },
                                    [
                                      _c(
                                        "a-select",
                                        {
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            mode: "multiple",
                                            allowClear: "",
                                            placeholder: "请选择统筹人员",
                                            "option-filter-prop": "label",
                                          },
                                          model: {
                                            value: _vm.detail.adminUserIds,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.detail,
                                                "adminUserIds",
                                                $$v
                                              )
                                            },
                                            expression: "detail.adminUserIds",
                                          },
                                        },
                                        _vm._l(_vm.userList, function (user) {
                                          return _c(
                                            "a-select-option",
                                            {
                                              key: user.userId,
                                              attrs: {
                                                disabled:
                                                  _vm.detail.adminUserIds
                                                    .length,
                                                label: user.userName,
                                              },
                                            },
                                            [
                                              _c("img", {
                                                staticStyle: {
                                                  width: "30px",
                                                  height: "30px",
                                                  "border-radius": "50%",
                                                  "margin-right": "10px",
                                                },
                                                attrs: {
                                                  src: user.avatar,
                                                  alt: "",
                                                },
                                              }),
                                              _c("span", [
                                                _vm._v(_vm._s(user.userName)),
                                              ]),
                                            ]
                                          )
                                        }),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "a-form-model-item",
                                    {
                                      attrs: {
                                        label: "本地化",
                                        prop: "canTra",
                                      },
                                    },
                                    [
                                      _c(
                                        "a-radio-group",
                                        {
                                          model: {
                                            value: _vm.detail.isLocal,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.detail,
                                                "isLocal",
                                                $$v
                                              )
                                            },
                                            expression: "detail.isLocal",
                                          },
                                        },
                                        [
                                          _c(
                                            "a-radio",
                                            { attrs: { value: true } },
                                            [
                                              _vm._v(
                                                "\n                    是\n                  "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "a-radio",
                                            { attrs: { value: false } },
                                            [
                                              _vm._v(
                                                "\n                    否\n                  "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "a-form-model-item",
                                    { attrs: { label: "使用系统" } },
                                    [
                                      _c("a-checkbox-group", {
                                        staticStyle: {
                                          display: "flex",
                                          "line-height": "40px",
                                        },
                                        attrs: { options: _vm.systemList },
                                        model: {
                                          value: _vm.detail.systemTypes,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.detail,
                                              "systemTypes",
                                              $$v
                                            )
                                          },
                                          expression: "detail.systemTypes",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm.detail.systemTypes &&
                                  _vm.detail.systemTypes.some(
                                    (item) =>
                                      item == "IMAGE_TRANSLATION_SYSTEM" ||
                                      item == "TEXT_TRANSLATION_SYSTEM"
                                  )
                                    ? _c(
                                        "a-form-model-item",
                                        { attrs: { label: "翻译语种" } },
                                        [
                                          _c(
                                            "a-select",
                                            {
                                              staticStyle: {
                                                width: "150px",
                                                "margin-right": "5px",
                                              },
                                              attrs: {
                                                showSearch: "",
                                                allowClear: "",
                                                showArrow: "",
                                                placeholder: "请选择源语种",
                                                "option-filter-prop": "label",
                                              },
                                              model: {
                                                value: _vm.detail.sourceLang,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.detail,
                                                    "sourceLang",
                                                    $$v
                                                  )
                                                },
                                                expression: "detail.sourceLang",
                                              },
                                            },
                                            [
                                              _c(
                                                "a-select-option",
                                                {
                                                  attrs: {
                                                    value: "SIMPLIFIED_CHINESE",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                    简体中文\n                  "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "a-select-option",
                                                {
                                                  attrs: {
                                                    value:
                                                      "TRADITIONAL_CHINESE",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                    繁體中文\n                  "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "a-select-option",
                                                { attrs: { value: "KOREAN" } },
                                                [
                                                  _vm._v(
                                                    "\n                    한국어\n                  "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "a-select-option",
                                                {
                                                  attrs: { value: "JAPANESE" },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                    日本語\n                  "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "a-select-option",
                                                { attrs: { value: "ENGLISH" } },
                                                [
                                                  _vm._v(
                                                    "\n                    ENGLISH\n                  "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "a-select-option",
                                                { attrs: { value: "FRENCH" } },
                                                [
                                                  _vm._v(
                                                    "\n                    Français\n                  "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "a-select-option",
                                                { attrs: { value: "GERMAN" } },
                                                [
                                                  _vm._v(
                                                    "\n                    Deutsch\n                  "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "a-select-option",
                                                {
                                                  attrs: { value: "italiano" },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                    ITALIAN\n                  "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c("a-icon", {
                                            attrs: { type: "arrow-right" },
                                          }),
                                          _c(
                                            "a-select",
                                            {
                                              staticStyle: {
                                                width: "150px",
                                                "margin-left": "5px",
                                              },
                                              attrs: {
                                                showSearch: "",
                                                allowClear: "",
                                                showArrow: "",
                                                placeholder: "请选择目标语种",
                                                "option-filter-prop": "label",
                                              },
                                              model: {
                                                value: _vm.detail.targetLang,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.detail,
                                                    "targetLang",
                                                    $$v
                                                  )
                                                },
                                                expression: "detail.targetLang",
                                              },
                                            },
                                            [
                                              _c(
                                                "a-select-option",
                                                {
                                                  attrs: {
                                                    value: "SIMPLIFIED_CHINESE",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                    简体中文\n                  "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "a-select-option",
                                                {
                                                  attrs: {
                                                    value:
                                                      "TRADITIONAL_CHINESE",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                    繁體中文\n                  "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "a-select-option",
                                                { attrs: { value: "KOREAN" } },
                                                [
                                                  _vm._v(
                                                    "\n                    한국어\n                  "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "a-select-option",
                                                {
                                                  attrs: { value: "JAPANESE" },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                    日本語\n                  "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "a-select-option",
                                                { attrs: { value: "ENGLISH" } },
                                                [
                                                  _vm._v(
                                                    "\n                    ENGLISH\n                  "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "a-select-option",
                                                { attrs: { value: "FRENCH" } },
                                                [
                                                  _vm._v(
                                                    "\n                    Français\n                  "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "a-select-option",
                                                { attrs: { value: "GERMAN" } },
                                                [
                                                  _vm._v(
                                                    "\n                    Deutsch\n                  "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "a-select-option",
                                                {
                                                  attrs: { value: "italiano" },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                    ITALIAN\n                  "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "a-form-model-item",
                                    {
                                      attrs: {
                                        label: "是否考核",
                                        prop: "hasKpi",
                                      },
                                    },
                                    [
                                      _c(
                                        "a-radio-group",
                                        {
                                          model: {
                                            value: _vm.detail.hasKpi,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.detail,
                                                "hasKpi",
                                                $$v
                                              )
                                            },
                                            expression: "detail.hasKpi",
                                          },
                                        },
                                        [
                                          _c(
                                            "a-radio",
                                            { attrs: { value: true } },
                                            [
                                              _vm._v(
                                                "\n                    是\n                  "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "a-radio",
                                            { attrs: { value: false } },
                                            [
                                              _vm._v(
                                                "\n                    否\n                  "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "a-form-model-item",
                                    {
                                      attrs: {
                                        label: "结算单位",
                                        prop: "calcUnits",
                                      },
                                    },
                                    [
                                      _c(
                                        "a-select",
                                        {
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            mode: "multiple",
                                            placeholder: "请选择",
                                          },
                                          model: {
                                            value: _vm.calcUnits,
                                            callback: function ($$v) {
                                              _vm.calcUnits = $$v
                                            },
                                            expression: "calcUnits",
                                          },
                                        },
                                        [
                                          _c(
                                            "a-select-option",
                                            {
                                              attrs: {
                                                disabled:
                                                  _vm.calcUnits.length > 1 &&
                                                  _vm.calcUnits.every(
                                                    (item) => item != "EACH"
                                                  ),
                                                value: "EACH",
                                              },
                                            },
                                            [_vm._v("个")]
                                          ),
                                          _c(
                                            "a-select-option",
                                            {
                                              attrs: {
                                                disabled:
                                                  _vm.calcUnits.length > 1 &&
                                                  _vm.calcUnits.every(
                                                    (item) => item != "CHAPTER"
                                                  ),
                                                value: "CHAPTER",
                                              },
                                            },
                                            [_vm._v("话")]
                                          ),
                                          _c(
                                            "a-select-option",
                                            {
                                              attrs: {
                                                disabled:
                                                  _vm.calcUnits.length > 1 &&
                                                  _vm.calcUnits.every(
                                                    (item) => item != "VOLUME"
                                                  ),
                                                value: "VOLUME",
                                              },
                                            },
                                            [_vm._v("部")]
                                          ),
                                          _c(
                                            "a-select-option",
                                            {
                                              attrs: {
                                                disabled:
                                                  _vm.calcUnits.length > 1 &&
                                                  _vm.calcUnits.every(
                                                    (item) => item != "PAGE"
                                                  ),
                                                value: "PAGE",
                                              },
                                            },
                                            [_vm._v("页")]
                                          ),
                                          _c(
                                            "a-select-option",
                                            {
                                              attrs: {
                                                disabled:
                                                  _vm.calcUnits.length > 1 &&
                                                  _vm.calcUnits.every(
                                                    (item) => item != "THOUSAND"
                                                  ),
                                                value: "THOUSAND",
                                              },
                                            },
                                            [_vm._v("千字")]
                                          ),
                                          _c(
                                            "a-select-option",
                                            {
                                              attrs: {
                                                disabled:
                                                  _vm.calcUnits.length > 1 &&
                                                  _vm.calcUnits.every(
                                                    (item) => item != "WORD"
                                                  ),
                                                value: "WORD",
                                              },
                                            },
                                            [_vm._v("字")]
                                          ),
                                          _c(
                                            "a-select-option",
                                            {
                                              attrs: {
                                                disabled:
                                                  _vm.calcUnits.length > 1 &&
                                                  _vm.calcUnits.every(
                                                    (item) => item != "GRID"
                                                  ),
                                                value: "GRID",
                                              },
                                            },
                                            [_vm._v("格")]
                                          ),
                                          _c(
                                            "a-select-option",
                                            {
                                              attrs: {
                                                disabled:
                                                  _vm.calcUnits.length > 1 &&
                                                  _vm.calcUnits.every(
                                                    (item) => item != "PERCENT"
                                                  ),
                                                value: "PERCENT",
                                              },
                                            },
                                            [_vm._v("%")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm.detail.canTra
                                    ? _c(
                                        "a-form-model-item",
                                        { attrs: { label: "章节标题" } },
                                        [
                                          _c("a-checkbox", {
                                            attrs: { placeholder: "" },
                                            model: {
                                              value: _vm.detail.hasChapterTitle,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.detail,
                                                  "hasChapterTitle",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "detail.hasChapterTitle",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.detail.canTra
                                    ? _c(
                                        "a-form-model-item",
                                        { attrs: { label: "章节封面" } },
                                        [
                                          _c("a-checkbox", {
                                            attrs: { placeholder: "" },
                                            model: {
                                              value:
                                                _vm.detail.hasChapterCoverImage,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.detail,
                                                  "hasChapterCoverImage",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "detail.hasChapterCoverImage",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.detail.canTra
                                    ? _c(
                                        "a-form-model-item",
                                        { attrs: { label: "初翻" } },
                                        [
                                          _c("a-checkbox", {
                                            attrs: { placeholder: "" },
                                            model: {
                                              value: _vm.detail.isSimple,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.detail,
                                                  "isSimple",
                                                  $$v
                                                )
                                              },
                                              expression: "detail.isSimple",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "has",
                                  rawName: "v-has",
                                  value: "platform:info:page:view",
                                  expression: "'platform:info:page:view'",
                                },
                              ],
                            },
                            [
                              _c(
                                "info-item",
                                {
                                  attrs: {
                                    "label-width": _vm.labelWidth,
                                    label: "平台名称",
                                  },
                                },
                                [_vm._v(_vm._s(_vm.detail.platformName))]
                              ),
                              _c(
                                "info-item",
                                {
                                  attrs: {
                                    "label-width": _vm.labelWidth,
                                    label: "序号",
                                  },
                                },
                                [_vm._v(_vm._s(_vm.detail.sequenceNo))]
                              ),
                              _c(
                                "info-item",
                                {
                                  attrs: {
                                    "label-width": _vm.labelWidth,
                                    label: "所属部门",
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.detail.department
                                        ? _vm.detail.department.departName
                                        : "无"
                                    )
                                  ),
                                ]
                              ),
                              _c(
                                "info-item",
                                {
                                  attrs: {
                                    "label-width": _vm.labelWidth,
                                    label: "统筹",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {},
                                    _vm._l(
                                      _vm.detail.adminUsers,
                                      function (item) {
                                        return _c(
                                          "span",
                                          {
                                            key: item.userId,
                                            staticStyle: {
                                              "margin-right": "10px",
                                            },
                                            attrs: { color: "blue" },
                                          },
                                          [_vm._v(_vm._s(item.userName))]
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                ]
                              ),
                              _c(
                                "info-item",
                                {
                                  attrs: {
                                    "label-width": _vm.labelWidth,
                                    label: "本地化",
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.detail.isLocal ? "是" : "否")
                                  ),
                                ]
                              ),
                              _vm.detail.systemTypes &&
                              _vm.detail.systemTypes.length
                                ? _c(
                                    "info-item",
                                    {
                                      attrs: {
                                        "label-width": _vm.labelWidth,
                                        label: "使用系统",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.detail.systemTypes
                                            .map((item) => {
                                              return _vm.systemList.find(
                                                (i) => i.value == item
                                              ).label
                                            })
                                            .join(",")
                                        )
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm.detail.systemTypes &&
                              _vm.detail.systemTypes.some(
                                (item) =>
                                  item == "IMAGE_TRANSLATION_SYSTEM" ||
                                  item == "TEXT_TRANSLATION_SYSTEM"
                              )
                                ? _c(
                                    "info-item",
                                    {
                                      attrs: {
                                        "label-width": _vm.labelWidth,
                                        label: "翻译语种",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "margin-right": "5px",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.languageList.find(
                                                (item) =>
                                                  item.value ==
                                                  _vm.detail.sourceLang
                                              ) &&
                                                _vm.languageList.find(
                                                  (item) =>
                                                    item.value ==
                                                    _vm.detail.sourceLang
                                                ).label
                                            )
                                          ),
                                        ]
                                      ),
                                      _c("a-icon", {
                                        attrs: { type: "arrow-right" },
                                      }),
                                      _c(
                                        "span",
                                        {
                                          staticStyle: { "margin-left": "5px" },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.languageList.find(
                                                (item) =>
                                                  item.value ==
                                                  _vm.detail.sourceLang
                                              ) &&
                                                _vm.languageList.find(
                                                  (item) =>
                                                    item.value ==
                                                    _vm.detail.targetLang
                                                ).label
                                            )
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "info-item",
                                {
                                  attrs: {
                                    "label-width": _vm.labelWidth,
                                    label: "是否考核",
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.detail.hasKpi ? "是" : "否")
                                  ),
                                ]
                              ),
                              _c(
                                "info-item",
                                {
                                  attrs: {
                                    labelWidth: _vm.labelWidth,
                                    label: "结算单位",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.getCalcUnits(_vm.detail.calcUnits)
                                      )
                                  ),
                                ]
                              ),
                              _vm.detail.canTra
                                ? _c(
                                    "info-item",
                                    {
                                      attrs: {
                                        "label-width": _vm.labelWidth,
                                        label: "章节标题",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("hasOrNot")(
                                            _vm.detail.hasChapterTitle
                                          )
                                        )
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm.detail.canTra
                                ? _c(
                                    "info-item",
                                    {
                                      attrs: {
                                        "label-width": _vm.labelWidth,
                                        label: "章节封面",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("hasOrNot")(
                                            _vm.detail.hasChapterCoverImage
                                          )
                                        )
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm.detail.canTra
                                ? _c(
                                    "info-item",
                                    {
                                      attrs: {
                                        "label-width": _vm.labelWidth,
                                        label: "初翻",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("bool")(_vm.detail.isSimple)
                                        )
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                    ]),
              ]),
              _vm.detail.systemTypes && _vm.detail.systemTypes.length
                ? _c(
                    "a-tab-pane",
                    {
                      key: "2",
                      attrs: {
                        disabled: _vm.isEditPlatform,
                        tab: "翻译设定",
                        "force-render": "",
                      },
                    },
                    [
                      _c("tpl-guide", {
                        ref: "tplTerms",
                        attrs: {
                          parent: _vm.detail,
                          userInfo: _vm.userInfo,
                          isAdmin: _vm.isAdmin,
                          tab: _vm.tab,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "a-tab-pane",
                {
                  key: "3",
                  attrs: { disabled: _vm.isEditPlatform, tab: "阶段" },
                },
                [
                  _c("tpl-stage", {
                    ref: "tplStage",
                    attrs: { parent: _vm.detail, tab: _vm.tab },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }