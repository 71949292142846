<template>
  <detail-layout :title="title">
    <div slot="actions" v-if="isAddPlatform">
      <div class="actions flex jc-end mb-4">
        <a-button icon="save" class="mr-3" @click="savePlatform" :loading="nextProcessing" v-show="tab == '1'"
          >保存</a-button
        >
        <a-button type="primary" @click="primaryButtonClick" :loading="nextProcessing">{{
          primaryButtonText
        }}</a-button>
      </div>
    </div>
    <a-spin :spinning="nextProcessing">
      <a-tabs :activeKey="tab" @change="tabChange">
        <!-- <a-tab-pane key="1" tab="指南模版">
        <tpl-guide></tpl-guide>
      </a-tab-pane> -->
        <a-tab-pane key="1" tab="平台信息">
          <div v-if="isAddPlatform">
            <a-form-model ref="form" :model="detail" :rules="rules" v-bind="layout">
              <a-form-model-item label="平台名称" prop="platformName">
                <a-input v-model="detail.platformName" placeholder="请输入平台名称"></a-input>
              </a-form-model-item>
              <a-form-model-item label="序号">
                <a-input v-model="detail.sequenceNo" placeholder="序号"></a-input>
              </a-form-model-item>
              <a-form-model-item label="所属部门" prop="departmentId">
                <a-tree-select
                  :treeDefaultExpandAll="true"
                  v-model="detail.departmentId"
                  style="width: 100%"
                  :tree-data="departList"
                  search-placeholder="选择部门"
                  @change="changeDepart"
                />
              </a-form-model-item>
              <a-form-model-item label="统筹" prop="adminUserIds">
                <a-select
                  style="width: 100%"
                  mode="multiple"
                  allowClear
                  placeholder="请选择统筹人员"
                  option-filter-prop="label"
                  v-model="detail.adminUserIds"
                >
                  <a-select-option
                    :disabled="detail.adminUserIds.length"
                    v-for="user in userList"
                    :label="user.userName"
                    :key="user.userId"
                  >
                    <img
                      style="width:30px;height:30px;border-radius:50%;margin-right:10px;"
                      :src="user.avatar"
                      alt=""
                    />
                    <span>{{ user.userName }}</span>
                  </a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="本地化" prop="canTra">
                <a-radio-group
                  v-model="detail.isLocal"
                  :options="[
                    { label: '是', value: true },
                    { label: '否', value: false }
                  ]"
                />
              </a-form-model-item>
              <a-form-model-item label="使用系统">
                <!--                 <a-radio-group v-model="detail.formType">
                  <a-radio value="COMIC">
                    漫画
                  </a-radio>
                  <a-radio value="NOVEL">
                    文字
                  </a-radio></a-radio-group
                > -->
                <a-checkbox-group
                  style="display:flex;line-height:40px"
                  v-model="detail.systemTypes"
                  :options="systemList"
                />
              </a-form-model-item>
              <a-form-model-item
                label="翻译语种"
                v-if="
                  detail.systemTypes &&
                    detail.systemTypes.some(
                      item => item == 'IMAGE_TRANSLATION_SYSTEM' || item == 'TEXT_TRANSLATION_SYSTEM'
                    )
                "
              >
                <a-select
                  showSearch
                  allowClear
                  showArrow
                  placeholder="请选择源语种"
                  v-model="detail.sourceLang"
                  style="width:150px;margin-right:5px"
                  option-filter-prop="label"
                >
                  <a-select-option value="SIMPLIFIED_CHINESE">
                    简体中文
                  </a-select-option>
                  <a-select-option value="TRADITIONAL_CHINESE">
                    繁體中文
                  </a-select-option>
                  <a-select-option value="KOREAN">
                    한국어
                  </a-select-option>
                  <a-select-option value="JAPANESE">
                    日本語
                  </a-select-option>
                  <a-select-option value="ENGLISH">
                    ENGLISH
                  </a-select-option>
                  <a-select-option value="FRENCH">
                    Français
                  </a-select-option>
                  <a-select-option value="GERMAN">
                    Deutsch
                  </a-select-option>
                  <a-select-option value="italiano">
                    ITALIAN
                  </a-select-option>
                </a-select>
                <a-icon type="arrow-right" />
                <a-select
                  showSearch
                  allowClear
                  showArrow
                  placeholder="请选择目标语种"
                  style="width:150px;margin-left:5px"
                  v-model="detail.targetLang"
                  option-filter-prop="label"
                >
                  <a-select-option value="SIMPLIFIED_CHINESE">
                    简体中文
                  </a-select-option>
                  <a-select-option value="TRADITIONAL_CHINESE">
                    繁體中文
                  </a-select-option>
                  <a-select-option value="KOREAN">
                    한국어
                  </a-select-option>
                  <a-select-option value="JAPANESE">
                    日本語
                  </a-select-option>
                  <a-select-option value="ENGLISH">
                    ENGLISH
                  </a-select-option>
                  <a-select-option value="FRENCH">
                    Français
                  </a-select-option>
                  <a-select-option value="GERMAN">
                    Deutsch
                  </a-select-option>
                  <a-select-option value="italiano">
                    ITALIAN
                  </a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="是否考核" prop="hasKpi">
                <a-radio-group v-model="detail.hasKpi">
                  <a-radio :value="true">
                    是
                  </a-radio>
                  <a-radio :value="false">
                    否
                  </a-radio></a-radio-group
                >
              </a-form-model-item>
              <a-form-model-item label="结算单位" prop="calcUnits">
                <a-select
                  option-filter-prop="label"
                  @filterOption="filterOption"
                  style="width: 100%"
                  mode="multiple"
                  placeholder="请选择"
                  v-model="calcUnits"
                >
                  <a-select-option
                    label="个"
                    :disabled="calcUnits.length > 1 && calcUnits.every(item => item != 'EACH')"
                    value="EACH"
                    >个</a-select-option
                  >
                  <a-select-option
                    label="话"
                    :disabled="calcUnits.length > 1 && calcUnits.every(item => item != 'CHAPTER')"
                    value="CHAPTER"
                    >话</a-select-option
                  >
                  <a-select-option
                    label="部"
                    :disabled="calcUnits.length > 1 && calcUnits.every(item => item != 'VOLUME')"
                    value="VOLUME"
                    >部</a-select-option
                  >
                  <a-select-option
                    label="页"
                    :disabled="calcUnits.length > 1 && calcUnits.every(item => item != 'PAGE')"
                    value="PAGE"
                    >页</a-select-option
                  >
                  <a-select-option
                    label="千字"
                    :disabled="calcUnits.length > 1 && calcUnits.every(item => item != 'THOUSAND')"
                    value="THOUSAND"
                    >千字</a-select-option
                  >
                  <a-select-option
                    label="字"
                    :disabled="calcUnits.length > 1 && calcUnits.every(item => item != 'WORD')"
                    value="WORD"
                    >字</a-select-option
                  >
                  <a-select-option
                    label="格"
                    :disabled="calcUnits.length > 1 && calcUnits.every(item => item != 'GRID')"
                    value="GRID"
                    >格</a-select-option
                  >
                  <a-select-option
                    label="%"
                    :disabled="calcUnits.length > 1 && calcUnits.every(item => item != 'PERCENT')"
                    value="PERCENT"
                    >%</a-select-option
                  >
                </a-select>
              </a-form-model-item>
              <a-form-model-item v-if="detail.canTra" label="章节标题">
                <a-checkbox v-model="detail.hasChapterTitle" placeholder=""></a-checkbox>
              </a-form-model-item>
              <a-form-model-item v-if="detail.canTra" label="章节封面">
                <a-checkbox v-model="detail.hasChapterCoverImage" placeholder=""></a-checkbox>
              </a-form-model-item>
              <!--               <a-form-model-item label="作者信息">
                <a-checkbox
                  v-model="detail.hasAuthor"
                  placeholder=""
                ></a-checkbox>
              </a-form-model-item> -->
              <a-form-model-item v-if="detail.canTra" label="初翻">
                <a-checkbox v-model="detail.isSimple" placeholder=""></a-checkbox>
              </a-form-model-item>
            </a-form-model>
          </div>
          <div v-else>
            <div class="actions flex jc-end mb-4">
              <a-button
                class="mr-3"
                :loading="activeLoading"
                :type="detail.active ? 'primary' : ''"
                v-has="'platform:active'"
                icon="check"
                :disabled="detail.active"
                @click="saveActive"
              >
                BOSS确认
              </a-button>
              <a-button
                class="mr-3"
                v-if="checkPermission('platform:active:view') && !checkPermission('platform:active')"
                icon="check"
                :disabled="true"
              >
                BOSS确认
              </a-button>
              <a-button
                type="danger"
                class="mr-3"
                v-if="detail.isTongChou"
                @click="delPlatform"
                v-has="'platform:list:button:delete'"
                icon="delete"
                >删除</a-button
              >
              <a-button v-if="isEditPlatform" type="default" class="mr-3" @click="editCancel">取消</a-button>
              <a-button
                type="primary"
                v-if="detail.isTongChou"
                v-has="'platform:info:button:edit'"
                icon="edit"
                @click="editPlatform"
              >
                {{ !isEditPlatform ? '编辑' : '保存' }}
              </a-button>
            </div>
            <div v-if="isEditPlatform">
              <a-form-model ref="form" :model="detail" :rules="rules" v-bind="layout">
                <a-form-model-item label="平台名称" prop="platformName">
                  <a-input v-model="detail.platformName" placeholder="请输入平台名称"></a-input>
                </a-form-model-item>
                <a-form-model-item label="序号">
                  <a-input v-model="detail.sequenceNo" placeholder="序号"></a-input>
                </a-form-model-item>
                <a-form-model-item label="所属部门" prop="departmentId">
                  <a-tree-select
                    v-model="detail.departmentId"
                    :treeDefaultExpandAll="true"
                    style="width: 100%"
                    :tree-data="departList"
                    search-placeholder="选择部门"
                    @change="changeDepart"
                  />
                </a-form-model-item>
                <a-form-model-item label="统筹" prop="adminUserIds">
                  <a-select
                    style="width: 100%"
                    mode="multiple"
                    allowClear
                    placeholder="请选择统筹人员"
                    option-filter-prop="label"
                    v-model="detail.adminUserIds"
                  >
                    <a-select-option
                      :disabled="detail.adminUserIds.length"
                      v-for="user in userList"
                      :label="user.userName"
                      :key="user.userId"
                    >
                      <img
                        style="width:30px;height:30px;border-radius:50%;margin-right:10px;"
                        :src="user.avatar"
                        alt=""
                      />
                      <span>{{ user.userName }}</span>
                    </a-select-option>
                  </a-select>
                </a-form-model-item>
                <a-form-model-item label="本地化" prop="canTra">
                  <a-radio-group v-model="detail.isLocal">
                    <a-radio :value="true">
                      是
                    </a-radio>
                    <a-radio :value="false">
                      否
                    </a-radio></a-radio-group
                  >
                </a-form-model-item>
                <a-form-model-item label="使用系统">
                  <!--                 <a-radio-group v-model="detail.formType">
                  <a-radio value="COMIC">
                    漫画
                  </a-radio>
                  <a-radio value="NOVEL">
                    文字
                  </a-radio></a-radio-group
                > -->
                  <a-checkbox-group
                    style="display:flex;line-height:40px"
                    v-model="detail.systemTypes"
                    :options="systemList"
                  />
                </a-form-model-item>
                <a-form-model-item
                  label="翻译语种"
                  v-if="
                    detail.systemTypes &&
                      detail.systemTypes.some(
                        item => item == 'IMAGE_TRANSLATION_SYSTEM' || item == 'TEXT_TRANSLATION_SYSTEM'
                      )
                  "
                >
                  <a-select
                    showSearch
                    allowClear
                    showArrow
                    placeholder="请选择源语种"
                    style="width:150px;margin-right:5px"
                    v-model="detail.sourceLang"
                    option-filter-prop="label"
                  >
                    <a-select-option value="SIMPLIFIED_CHINESE">
                      简体中文
                    </a-select-option>
                    <a-select-option value="TRADITIONAL_CHINESE">
                      繁體中文
                    </a-select-option>
                    <a-select-option value="KOREAN">
                      한국어
                    </a-select-option>
                    <a-select-option value="JAPANESE">
                      日本語
                    </a-select-option>
                    <a-select-option value="ENGLISH">
                      ENGLISH
                    </a-select-option>
                    <a-select-option value="FRENCH">
                      Français
                    </a-select-option>
                    <a-select-option value="GERMAN">
                      Deutsch
                    </a-select-option>
                    <a-select-option value="italiano">
                      ITALIAN
                    </a-select-option>
                  </a-select>
                  <a-icon type="arrow-right" />
                  <a-select
                    showSearch
                    allowClear
                    showArrow
                    placeholder="请选择目标语种"
                    style="width:150px;margin-left:5px"
                    v-model="detail.targetLang"
                    option-filter-prop="label"
                  >
                    <a-select-option value="SIMPLIFIED_CHINESE">
                      简体中文
                    </a-select-option>
                    <a-select-option value="TRADITIONAL_CHINESE">
                      繁體中文
                    </a-select-option>
                    <a-select-option value="KOREAN">
                      한국어
                    </a-select-option>
                    <a-select-option value="JAPANESE">
                      日本語
                    </a-select-option>
                    <a-select-option value="ENGLISH">
                      ENGLISH
                    </a-select-option>
                    <a-select-option value="FRENCH">
                      Français
                    </a-select-option>
                    <a-select-option value="GERMAN">
                      Deutsch
                    </a-select-option>
                    <a-select-option value="italiano">
                      ITALIAN
                    </a-select-option>
                  </a-select>
                </a-form-model-item>
                <a-form-model-item label="是否考核" prop="hasKpi">
                  <a-radio-group v-model="detail.hasKpi">
                    <a-radio :value="true">
                      是
                    </a-radio>
                    <a-radio :value="false">
                      否
                    </a-radio></a-radio-group
                  >
                </a-form-model-item>
                <a-form-model-item label="结算单位" prop="calcUnits">
                  <a-select style="width: 100%" mode="multiple" placeholder="请选择" v-model="calcUnits">
                    <a-select-option
                      :disabled="calcUnits.length > 1 && calcUnits.every(item => item != 'EACH')"
                      value="EACH"
                      >个</a-select-option
                    >
                    <a-select-option
                      :disabled="calcUnits.length > 1 && calcUnits.every(item => item != 'CHAPTER')"
                      value="CHAPTER"
                      >话</a-select-option
                    >
                    <a-select-option
                      :disabled="calcUnits.length > 1 && calcUnits.every(item => item != 'VOLUME')"
                      value="VOLUME"
                      >部</a-select-option
                    >
                    <a-select-option
                      :disabled="calcUnits.length > 1 && calcUnits.every(item => item != 'PAGE')"
                      value="PAGE"
                      >页</a-select-option
                    >
                    <a-select-option
                      :disabled="calcUnits.length > 1 && calcUnits.every(item => item != 'THOUSAND')"
                      value="THOUSAND"
                      >千字</a-select-option
                    >
                    <a-select-option
                      :disabled="calcUnits.length > 1 && calcUnits.every(item => item != 'WORD')"
                      value="WORD"
                      >字</a-select-option
                    >
                    <a-select-option
                      :disabled="calcUnits.length > 1 && calcUnits.every(item => item != 'GRID')"
                      value="GRID"
                      >格</a-select-option
                    >
                    <a-select-option
                      :disabled="calcUnits.length > 1 && calcUnits.every(item => item != 'PERCENT')"
                      value="PERCENT"
                      >%</a-select-option
                    >
                  </a-select>
                </a-form-model-item>
                <!--                 <a-form-model-item label="翻译系统" prop="canTra">
                  <a-switch v-model="detail.canTra" @change="switchChange" />
                </a-form-model-item> -->
                <a-form-model-item v-if="detail.canTra" label="章节标题">
                  <a-checkbox v-model="detail.hasChapterTitle" placeholder=""></a-checkbox>
                </a-form-model-item>
                <a-form-model-item v-if="detail.canTra" label="章节封面">
                  <a-checkbox v-model="detail.hasChapterCoverImage" placeholder=""></a-checkbox>
                </a-form-model-item>
                <!--                 <a-form-model-item label="作者信息">
                  <a-checkbox
                    v-model="detail.hasAuthor"
                    placeholder=""
                  ></a-checkbox>
                </a-form-model-item> -->
                <a-form-model-item v-if="detail.canTra" label="初翻">
                  <a-checkbox v-model="detail.isSimple" placeholder=""></a-checkbox>
                </a-form-model-item>
              </a-form-model>
            </div>
            <div v-else v-has="'platform:info:page:view'">
              <info-item :label-width="labelWidth" label="平台名称">{{ detail.platformName }}</info-item>
              <info-item :label-width="labelWidth" label="序号">{{ detail.sequenceNo }}</info-item>
              <info-item :label-width="labelWidth" label="所属部门">{{
                detail.department ? detail.department.departName : '无'
              }}</info-item>
              <info-item :label-width="labelWidth" label="统筹">
                <div class="">
                  <span style="margin-right:10px" color="blue" v-for="item in detail.adminUsers" :key="item.userId">{{
                    item.userName
                  }}</span>
                </div>
              </info-item>
              <info-item :label-width="labelWidth" label="本地化">{{ detail.isLocal ? '是' : '否' }}</info-item>
              <!--               <info-item :label-width="labelWidth" v-if="detail.isLocal" label="类型">{{
                detail.formType === 'COMIC' ? '漫画' : detail.formType === 'NOVEL' ? '文字' : ''
              }}</info-item> -->
              <info-item
                :label-width="labelWidth"
                v-if="detail.systemTypes && detail.systemTypes.length"
                label="使用系统"
                >{{
                  detail.systemTypes
                    .map(item => {
                      return systemList.find(i => i.value == item).label
                    })
                    .join(',')
                }}</info-item
              >
              <info-item
                :label-width="labelWidth"
                v-if="
                  detail.systemTypes &&
                    detail.systemTypes.some(
                      item => item == 'IMAGE_TRANSLATION_SYSTEM' || item == 'TEXT_TRANSLATION_SYSTEM'
                    )
                "
                label="翻译语种"
              >
                <span style="margin-right:5px">{{
                  languageList.find(item => item.value == detail.sourceLang) &&
                    languageList.find(item => item.value == detail.sourceLang).label
                }}</span>
                <a-icon type="arrow-right" />
                <span style="margin-left:5px">{{
                  languageList.find(item => item.value == detail.sourceLang) &&
                    languageList.find(item => item.value == detail.targetLang).label
                }}</span>
              </info-item>
              <info-item :label-width="labelWidth" label="是否考核">{{ detail.hasKpi ? '是' : '否' }}</info-item>
              <!--               <info-item label="翻译系统" :label-width="labelWidth">
                {{detail.canTra?'是':'否'}}
              </info-item> -->
              <info-item :labelWidth="labelWidth" label="结算单位"> {{ getCalcUnits(detail.calcUnits) }}</info-item>
              <info-item v-if="detail.canTra" :label-width="labelWidth" label="章节标题">{{
                detail.hasChapterTitle | hasOrNot
              }}</info-item>
              <info-item v-if="detail.canTra" :label-width="labelWidth" label="章节封面">{{
                detail.hasChapterCoverImage | hasOrNot
              }}</info-item>
              <!--               <info-item
                :label-width="labelWidth"
                label="作者信息"
              >{{ detail.hasAuthor | hasOrNot }}</info-item> -->
              <info-item v-if="detail.canTra" :label-width="labelWidth" label="初翻">{{
                detail.isSimple | bool
              }}</info-item>
            </div>
          </div>
        </a-tab-pane>
        <a-tab-pane
          :disabled="isEditPlatform"
          v-if="detail.systemTypes && detail.systemTypes.length"
          key="2"
          tab="翻译设定"
          force-render
        >
          <tpl-guide ref="tplTerms" :parent="detail" :userInfo="userInfo" :isAdmin="isAdmin" :tab="tab"></tpl-guide>
        </a-tab-pane>
        <!--         <a-tab-pane v-if="isAdmin&&detail.isLocal" key="3" tab="术语表模版" force-render>
          <tpl-terms ref="tplTerms" :parent="detail" :tab="tab"></tpl-terms>
        </a-tab-pane> -->
        <a-tab-pane :disabled="isEditPlatform" key="3" tab="阶段">
          <tpl-stage ref="tplStage" :parent="detail" :tab="tab"></tpl-stage>
        </a-tab-pane>
        <!--         <a-tab-pane :disabled="isEditPlatform" key="5" v-if="detail.isLocal && detail.hasKpi" tab="作品详情">
          <product-detail type="PLATFORM" :parent="detail"> </product-detail>
        </a-tab-pane>
        <a-tab-pane :disabled="isEditPlatform" key="6" v-if="detail.isLocal && detail.hasKpi" tab="系数参数">
          <product-detail type="PLATFORM" domain="TASK_DETAIL" :parent="detail"> </product-detail>
        </a-tab-pane> -->
      </a-tabs>
    </a-spin>
  </detail-layout>
</template>

<script>
import TplGuide from './tpl-guide'
import TplTerms from './tpl-terms'
import TplStage from './tpl-stage'
import ProductDetail from '@/views/production/details/ProductDetail.vue'
import { getAction, postAction } from '@/api/manage'
import PlatformModal from '../../modules/PlatformModal'
import { OpenDrawerMixins } from '@/mixins/OpenDrawerMixins'
import LeftRow from '@/components/my/LeftRow.vue'
import RightRow from '@/components/my/RightRow.vue'
import { deletePlatform } from '@/api/productTree'
import { USER_INFO } from '@/store/mutation-types'
import { checkPermission } from '@/utils/hasPermission'
import Vue from 'vue'
export default {
  components: {
    TplGuide,
    TplTerms,
    TplStage,
    ProductDetail,
    PlatformModal,
    LeftRow,
    RightRow
  },
  mixins: [OpenDrawerMixins],
  data() {
    let validatePlatformName = async (rule, value, callback) => {
      if (this.isAddPlatform) {
        if (!value) {
          callback(new Error('请输入平台名称'))
        } else {
          const res = await getAction('/sys/api/checkDataPresent', {
            table: 'mx_platform',
            property: 'platformName',
            value
          })
          try {
            if (res.data) {
              callback(new Error('平台名称已存在'))
            } else {
              callback()
            }
          } catch (err) {
            callback()
          }
        }
      } else {
        callback()
      }
    }
    return {
      title: '',
      relationId: '',
      labelWidth: '80',
      primaryButtonText: '下一步',
      nextProcessing: false,
      saveProcessing: false,
      calcUnits: [],
      detail: {
        sequenceNo: 0,
        adminUsers: [],
        assistantUsers: [],
        adminUserIds: [],
        departmentId: '',
        hasChapterTitle: true,
        hasChapterCoverImage: true,
        platformName: '',
        isSimple: false,
        canTra: false,
        active: false,
        isLocal: true,
        isTongChou: false,
        formType: 'COMIC',
        hasKpi: true,
        systemTypes: [],
        sourceLang: null,
        targetLang: null
      },
      systemList: [
        { value: 'IMAGE_TRANSLATION_SYSTEM', label: '图片翻译系统' },
        { value: 'TEXT_TRANSLATION_SYSTEM', label: '文字翻译系统' },
        { value: 'IMAGE_REVIEW_SYSTEM', label: '查稿系统' }
      ],
      languageList: [
        { value: 'SIMPLIFIED_CHINESE', label: '简体中文' },
        { value: 'TRADITIONAL_CHINESE', label: '繁體中文' },
        { value: 'KOREAN', label: '한국어' },
        { value: 'ENGLISH', label: 'ENGLISH' },
        { value: 'FRENCH', label: 'Français' },
        { value: 'GERMAN', label: 'Deutsch' },
        { value: 'JAPANESE', label: '日本語' },
        { value: 'ITALIAN', label: 'italiano' }
      ],
      oldData: {},
      departIds: [],
      tab: '1',
      isAddPlatform: false,
      isEditPlatform: false,
      rules: {
        platformName: [{ validator: validatePlatformName, trigger: 'blur' }],
        adminUserIds: [{ required: true, message: '请选择统筹人员', trigger: 'blur' }],
        departmentId: [{ required: true, message: '请选择所属部门' }]
      },
      layout: {
        labelCol: {
          style: 'width: 80px',
          span: 6
        },
        wrapperCol: {
          span: 18
        }
      },
      isSave: false,
      defaultActiveKey: '1',
      userList: [],
      activeLoading: false,
      isAdmin: false,
      userInfo: {}
    }
  },
  methods: {
    editCancel() {
      this.isEditPlatform = false
      this.detail = JSON.parse(JSON.stringify(this.oldData))
    },
    checkPermission(type) {
      return checkPermission(type)
    },
    async saveActive() {
      this.activeLoading = true
      this.detail.active = !this.detail.active
      let formData = Object.assign({}, this.detail)
      await postAction('platform/add', formData)
      this.getDetail()
      this.activeLoading = false
    },
    loadData() {
      this.getDetail()
    },
    switchChange(e) {
      if (!e) {
        this.detail.hasChapterTitle = false
        this.detail.hasChapterCoverImage = false
        this.detail.isSimple = false
      } else {
        this.detail.hasChapterTitle = true
        this.detail.hasChapterCoverImage = true
        this.detail.isSimple = true
      }
    },
    async tabChange(tab) {
      sessionStorage.setItem('current_name_two', tab)
      this.defaultActiveKey = tab
      if (this.tab == '1' && this.isAddPlatform && !this.isSave) {
        this.nextProcessing = true
        let isSave = await this._saveForm()
        this.nextProcessing = false
        if (!isSave) return
      }
      if (tab == '1') {
        this.loadData()
      }
      // console.log(tab);
      let newData = JSON.stringify(this.detail)
      let oldData = JSON.stringify(this.oldData)
      if (this.isEditPlatform && oldData != newData) {
        this.$confirm({
          title: '提示',
          content: '您尚未保存数据，需要保存吗？',
          okText: '保存',
          cancelText: '切换',
          maskClosable: true,
          onOk: async () => {
            if (this.tab == '1') await this.editPlatform()
            this.isEditPlatform = false
            this.tab = tab
            if (this.tab != '3') {
              this.primaryButtonText = '下一步'
            } else {
              this.primaryButtonText = '完成'
              if (!this.isSave && this.isAddPlatform) {
                this.nextProcessing = true
                let ret = await this._saveForm()
                this.nextProcessing = false
                if (ret) {
                  this.tabChange('3')
                  if (this.$refs.tplTerms) {
                    this.$refs.tplTerms.loadData()
                  }
                  if (this.$refs.tplStage) {
                    this.$refs.tplStage.loadData()
                  }
                }
              }
            }
          },
          onCancel: async () => {
            this.isEditPlatform = false
            this.tab = tab
            if (this.tab != '3') {
              this.primaryButtonText = '下一步'
            } else {
              this.primaryButtonText = '完成'
              if (!this.isSave && this.isAddPlatform) {
                this.nextProcessing = true
                let ret = await this._saveForm()
                this.nextProcessing = false
                if (ret) {
                  this.tabChange('3')
                  if (this.$refs.tplTerms) {
                    this.$refs.tplTerms.loadData()
                  }
                  if (this.$refs.tplStage) {
                    this.$refs.tplStage.loadData()
                  }
                }
              }
            }
          }
        })
      } else {
        this.tab = tab
        this.isEditPlatform = false
        if (this.tab != '3') {
          this.tab = tab
          this.primaryButtonText = '下一步'
        } else {
          this.primaryButtonText = '完成'
          if (!this.isSave && this.isAddPlatform) {
            this.nextProcessing = true
            let ret = await this._saveForm()
            this.nextProcessing = false
            if (ret) {
              this.tabChange('3')
              if (this.$refs.tplTerms) {
                this.$refs.tplTerms.loadData()
              }
              if (this.$refs.tplStage) {
                this.$refs.tplStage.loadData()
              }
            }
          }
        }
      }
    },
    async primaryButtonClick() {
      switch (this.tab) {
        case '1':
          /* this.nextProcessing = true
          let ret = await this._saveForm()
          this.nextProcessing = false */
          /*           if (this.detail.isLocal) {
            this.tabChange('4')
          } else {
            this.tabChange('6')
          } */
          this.tabChange('3')
          /*           if (this.$refs.tplTerms) {
            this.$refs.tplTerms.loadData()
          }
          if (this.$refs.tplStage) {
            this.$refs.tplStage.loadData()
          } */
          break
        case '2':
          this.tabChange('3')
          break
        case '3':
          this.$router.push({ path: '/production/manage' })
          break
        case '4':
          this.tabChange('5')
          break
        case '5':
          this.tabChange('6')
          break
      }
    },
    async savePlatform() {
      this.nextProcessing = true
      let ret = await this._saveForm()
      this.nextProcessing = false
      if (ret) {
        this.$router.push({ path: '/production/manage' })
      }
    },
    async _saveForm() {
      let form = this.$refs['form']
      let valid = await form.validate().catch(e => {
        return false
      })
      if (!valid) return
      let formData = Object.assign({}, this.detail)
      let userMap = {}
      this.userList.forEach(one => {
        userMap[one.id] = one.realname
      })
      formData.adminUsers = formData.adminUserIds.map(one => {
        return { userId: one, userName: userMap[one] }
      })
      formData.calcUnits = this.calcUnits.map(item => {
        return { type: item, value: null }
      })
      formData.department = { departId: formData.departmentId }
      formData.hasChapterCoverImage = false
      formData.hasChapterTitle = false
      let response = await postAction('platform/add', formData)
      if (response.success) {
        this.isSave = true
        this.detail.adminUsers = Object.assign({}, formData.adminUsers)
        if (!this.detail.id) {
          this.detail.id = response.data.platformId
          return true
          /*           const res = await postAction('/productDetail/copy/up', {
            type: 'PLATFORM',
            relationId: response.data.platformId
          })
          if (res.success) {
            this.detail.id = response.data.platformId
            return true
          } else {
            this.$message.error(res.message)
          } */
        }
        this.detail.id = response.data.platformId
        return true
      } else {
        this.$message.error({ content: response.message })
        return false
      }
    },
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    getCalcUnits(data) {
      let text = []
      data?.forEach(item => {
        let unit = ''
        switch (item.type) {
          case 'EACH':
            unit = '个'
            break
          case 'CHAPTER':
            unit = '话'
            break
          case 'VOLUME':
            unit = '部'
            break
          case 'PAGE':
            unit = '页'
            break
          case 'WORD':
            unit = '字'
            break
          case 'THOUSAND':
            unit = '千字'
            break
          case 'GRID':
            unit = '格'
            break
          case 'PERCENT':
            unit = '%'
            break
        }
        text.push(unit)
      })
      text = text.join('，')
      return text
    },
    getDetail() {
      let id = this.$route.params.id
      if (id != 'new') {
        getAction('/platform/get', { id: this.$route.params.id }).then(({ data }) => {
          this.detail = Object.assign(this.detail, data)
          this.detail.departmentId = this.detail.department.departId
          this.detail.adminUserIds = this.detail.adminUsers.map(one => {
            return one.userId
          })
          this.title = this.detail.platformName
          postAction('/sys/user/queryUser', {
            departmentIds: this.detail.departmentId ? [this.detail.departmentId] : null
            /* positionId: this.positionId, */
          }).then(({ data }) => {
            this.userList = []
            data.forEach(item => {
              if (!item.enabled) {
                item.positions.forEach(i => {
                  if (i.positionName == '统筹') {
                    this.userList.push(item)
                  }
                })
              }
            })
            this.userList = this.userList.reduce((arr, v) => {
              if (!arr.some(i => i.userId == v.userId)) {
                arr.push(v)
              }
              return arr
            }, [])
          })
          let userInfo = Vue.ls.get(USER_INFO)
          if (
            userInfo.roles.some(item => item.roleCode == 'boss' || item.roleCode == 'admin' || item.roleCode == 'hr')
          ) {
            this.detail.isTongChou = true
          } else if (userInfo.roles.some(item => item.roleCode == 'tongchou')) {
            if (
              this.detail.adminUsers.some(item => {
                return item.userId == userInfo.id
              })
            ) {
              this.detail.isTongChou = true
            } else {
              this.detail.isTongChou = false
            }
          }
          if (this.title) {
            document.title = this.title + ' - 漫小崔之家'
          } else {
            document.title = '漫小崔之家'
          }
          this.oldData = JSON.parse(JSON.stringify(this.detail))
        })
      } else {
        document.title = '新建 - 漫小崔之家'
      }
    },
    async editPlatform() {
      if (!this.isEditPlatform) {
        this.isEditPlatform = true
        this.calcUnits = this.detail.calcUnits.map(item => item.type) || []
      } else {
        let ret = await this._saveForm()
        if (ret) {
          this.isEditPlatform = false
          let id = this.$route.params.id
          this.detail.id = id
          this.relationId = id
          this.getDetail()
        }
      }
    },
    openEdit() {
      this.curRow = this.detail
      this.drawerShow = true
      this.isNewForm = true
      this.isEditForm = true
    },
    changeDepart(v, t, { triggerNode }) {
      if (t.length > 0 && this.detail.department) {
        this.detail.department.departName = t[0]
      }
      this.detail.adminUserIds = []
      this.getAllAdminUsers()
    },
    delPlatform() {
      this.$confirm({
        title: '确认删除',
        content: '是否删除当前平台?',
        onOk: () => {
          deletePlatform({ ids: this.detail.id }).then(res => {
            if (res.success) {
              this.$message.success(res.message)
              // 返回列表
              this.$router.go(-1)
            } else {
              this.$message.warning(res.message)
            }
          })
        }
      })
    },
    getAllAdminUsers() {
      postAction('/sys/user/queryUser', {
        departmentIds: this.detail.departmentId ? [this.detail.departmentId] : null
        /* positionId: this.positionId, */
      }).then(({ data }) => {
        this.userList = []
        data.forEach(item => {
          if (!item.enabled) {
            item.positions.forEach(i => {
              if (i.positionName == '统筹') {
                this.userList.push(item)
              }
            })
          }
        })
        let userInfo = Vue.ls.get(USER_INFO)
        this.userList = this.userList.reduce((arr, v) => {
          if (!arr.some(i => i.userId == v.userId)) {
            arr.push(v)
          }
          return arr
        }, [])
        if (userInfo.roles.some(item => item.roleCode == 'boss' || item.roleCode == 'admin')) return
        this.detail.adminUserIds = [userInfo.id]
        this.userList = this.userList.filter(item => item.userId == userInfo.id)
      })
    }
  },
  created() {
    this.userInfo = Vue.ls.get(USER_INFO)
    this.isAdmin = this.userInfo.roles.some(item => {
      return item.roleCode == 'admin' || item.roleCode == 'boss'
    })
    this.departIds = this.userInfo.departs
    let that = this
    if (this.userInfo.roles.some(item => item.roleCode == 'boss' || item.roleCode == 'admin')) {
      this.getDepartList()
    } else {
      this.getDepartList(this.departIds).then(() => {
        that.detail.departmentId = this.userInfo.departs.map(item => item.id)
        that.detail.departmentId.length > 1 && (that.detail.departmentId = '')
        that.detail.departmentId.length == 1 && (that.detail.departmentId = that.detail.departmentId[0])
        this.getAllAdminUsers()
      })
    }
    let id = this.$route.params.id
    this.relationId = id
    if (id === 'new') {
      this.title = '新建'
      this.isAddPlatform = true
      document.title = '新建平台 - 漫小崔之家'
      if (
        this.userInfo.roles.some(item => item.roleCode == 'boss' || item.roleCode == 'admin' || item.roleCode == 'hr')
      ) {
        this.detail.isTongChou = true
      }
    } else {
      this.detail.id = id
      this.relationId = id
      this.getDetail()
    }
    this.isAddPlatform && (this.tab = '1')
  },
  mounted() {}
}
</script>

<style lang="less" scoped>
.ant-select-selection__rendered {
  img {
    display: none;
  }
}
</style>
