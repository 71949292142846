var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "py-4" },
    [
      _c("guide", {
        ref: "guideRef",
        attrs: {
          propsProductionId: "",
          btnTitle: "添加",
          propsDepartmentId: _vm.departmentId,
          propsPlatformId: _vm.platformId,
          departmentIds: [_vm.parent.departmentId],
          isDefaultTypeId: true,
          userInfo: _vm.userInfo,
          isAdmin: _vm.isAdmin,
          propsUrl: _vm.url,
          isDetail: true,
          isUseBodyLayoutStyleProps: true,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }