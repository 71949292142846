<template>
  <div class="py-4">
    <!-- 指南 -->
    <guide
      ref="guideRef"
      propsProductionId=""
      btnTitle="添加"
      :propsDepartmentId="departmentId"
      :propsPlatformId="platformId"
      :departmentIds='[parent.departmentId]'
      :isDefaultTypeId="true"
      :userInfo='userInfo'
      :isAdmin='isAdmin'
      :propsUrl="url"
      :isDetail="true"
      :isUseBodyLayoutStyleProps="true"
    ></guide>
  </div>
</template>

<script>
import Guide from './guide/guide-base'
export default {
  components: {
    Guide,
  },
  props: {
    parent: {
      type: Object,
      default: () => {},
    },
    userInfo: {
      type: Object,
      default: () => ({}),
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
    tab: {
      type: String,
      default: '1',
    },
  },
  watch: {
    tab(n) {
      if (n == 2) {
        if (this.$route.params.id == 'new') {
          this.platformId = this.parent.id
          this.$refs.guideRef.id = this.platformId
          this.$refs.guideRef.platformId = this.platformId
          this.$refs.guideRef.loadData({}, this.platformId)
        }
      }
    },
  },
  data() {
    return {
      platformId: this.parent.id || this.$route.params.id,
      url: {
        list: '/platform/get_guide',
        delete: '/platform/delete_guide',
        deleteBatch: '/platform/delete_guide_batch',
        edit: '/platform/edit_guide',
        add: '/platform/add_guide',
      },
    }
  },
  computed: {
    departmentId: {
      get: function () {
        return this.parent.departmentId
      },
    },
  },
  created() {},
}
</script>

<style lang='less'>
</style>