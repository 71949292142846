var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-row",
    { staticClass: "row", attrs: { align: "middle" } },
    [
      _c("a-form-model-item", [
        _c("label", { staticClass: "label" }, [_vm._v(_vm._s(_vm.label))]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }