<template>
  <a-row class="row" align="middle" :style="{lineHeight: lineHeight1}">
    <a-form-model-item :prop="prop">
      <slot></slot>
    </a-form-model-item>
  </a-row>
</template>

<script>
export default {
  props: {
    prop: String,
    lineHeight1: '40px',
  },
}
</script>

<style lang="less" scoped>
.row {
  line-height: 40px;
}
</style>